import React, {useState} from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import img1 from '../Images/Wp/1.png'
import img2 from '../Images/Wp/2.png'
import img3 from '../Images/Wp/3.png'
import img4 from '../Images/Wp/4.png'
import img5 from '../Images/Wp/5.png'
import img7 from '../Images/Wp/6.png'
import img8 from '../Images/Wp/7.png'
import img9 from '../Images/Wp/8.png'
import img10 from '../Images/Wp/9.png'
import img11 from '../Images/Wp/10.png'
import img12 from '../Images/Wp/11.png'

const Slider = () => {
    const sliderArray = [
        {url: img1},
        {url: img2},
        {url: img3},
        {url: img4},
        {url: img5},
        {url: img7},
        {url: img8},
        {url: img9},
        {url: img10},
        {url: img11},
        {url: img12},
    ]

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? sliderArray.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === sliderArray.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };
    return (
        <div id={'slider'}>
            <h1 className={'text-4xl font-bold text-center pb-4 text-[#001b5e]'}>Slider</h1>
            <div className='max-w-[1040px] h-[780px] w-full m-auto py-16 px-4 relative group'>
                <div
                    style={{ backgroundImage: `url(${sliderArray[currentIndex].url})` }}
                    className='w-full h-full rounded-2xl bg-center bg-cover duration-500'
                ></div>
                {/* Left Arrow */}
                <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%]
            left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <BsChevronCompactLeft onClick={prevSlide} size={30} />
                </div>
                {/* Right Arrow */}
                <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%]
            right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <BsChevronCompactRight onClick={nextSlide} size={30} />
                </div>
                <div className='flex top-4 justify-center py-2'>
                    {sliderArray.map((slide, slideIndex) => (
                        <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className='text-2xl cursor-pointer'
                        >
                            <RxDotFilled />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Slider;